export default {
  GET(state, data) {
    state.paymentMethod = { ...data }
  },
  LIST(state, data) {
    state.paymentMethods = data
  },
  SET_FORM(state, data) {
    state.paymentMethodsForm = { ...data }
  },
}
