import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import { stringify } from 'qs'

const endpoint = 'payment-methods'
const Actions = crudActions(endpoint)
const setDefault = (ctx, data) => axios.post(`/${endpoint}/set-default`, { id: data })
const getActiveList = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  // eslint-disable-next-line no-param-reassign
  queryParams = { ...queryParams, is_active: 1 }
  axios.get(`/${endpoint}`, {
    params: queryParams,
    paramsSerializer: params => stringify(params),
  })
    .then(response => {
      commit('LIST', response.data.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const update = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)
const setPosition = (ctx, data) => axios.put(`${endpoint}/sort-order`, data)

export default {
  ...Actions,
  setPosition,
  getActiveList,
  update,
  setDefault,
}
