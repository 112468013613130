<template>
  <router-view />
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import paymentMethodsModule from '@/store/settings/general/payment-methods'

export default {
  name: 'PaymentMethods',
  provide: {
    paymentMethodsModuleName: 'paymentMethods',
  },
  created() {
    this.$emit('updateMenu', 'settings-payment-methods')
  },
  setup() {
    const MODULE_NAME = 'paymentMethods'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, paymentMethodsModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>
